<template>
  <div>
    <b-overlay
      :show="loader"
      rounded="sm"
      variant="dark"
      :opacity="0.1"
    >
      <b-container v-if="detail">
        <div class="d-flex justify-content-end mb-1">
          <div>
            <b-button
              variant="primary"
              size="md"
              @click="updateOrder"
            >
              <span class="align-middle">Update</span>
            </b-button>
          </div>
        </div>
        <b-row>
          <b-col>
            <b-card title="Customer Details">
              <b-list-group>
                <b-list-group-item><span class="font-weight-bold">Name:</span> {{ detail.customer.firstname }} {{ detail.customer.lastname }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Email:</span> {{ detail.customer.email }}</b-list-group-item>
                <b-list-group-item v-if="detail.customer.phone">
                  <span class="font-weight-bold">Phone:</span> <a :href="`tel:${detail.customer.phone}`">{{ detail.customer.phone }}</a>
                </b-list-group-item>
                <b-list-group-item v-else>
                  <span class="font-weight-bold">Phone:</span> N/A
                </b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Location: </span>
                  <a
                    target="_blank"
                    :href="`https://www.google.com/maps?q=${detail.customer.address1 || ''}${detail.customer.address2 ? `, ${detail.customer.address2}` : ''} ${detail.customer.postcode ? `, ${detail.customer.postcode}` : ''}`"
                  >{{ detail.customer.address1 || '' }}{{ detail.customer.address2 ? `, ${detail.customer.address2}` : '' }}{{ detail.customer.postcode ? `, ${detail.customer.postcode}` : '' }}
                  </a>
                </b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Billing Address: </span>
                  {{ detail.customer.postcode || '' }}{{ detail.customer.address1 ? `, ${detail.customer.address1}` : '' }}{{ detail.customer.address2 ? `, ${detail.customer.address2}` : '' }}
                </b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Payment Type: </span> {{ detail.payment_type }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Payment Status: </span> {{ detail.payment_status }}</b-list-group-item>
                <b-list-group-item>&nbsp;</b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
          <b-col>
            <b-card title="Order Details">
              <b-list-group>
                <b-list-group-item><span class="font-weight-bold">Total time: </span> {{ detail.total_time }} min</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Total amount: </span> £{{ detail.total_amount }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Paid amount: </span> {{ detail.payment_status === 'Paid Full' ? `£${detail.total_amount}` : `£${detail.details.advance_amount}` }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Remaining amount:</span> {{ detail.payment_status === 'Paid Full' ? '£0' : `£${detail.total_amount - detail.details.advance_amount}` }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Repair Date: </span> {{ detail.repair_date }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Promocode: </span>{{ detail.promo_code || 'N/A' }}</b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Status:&nbsp;&nbsp;</span>
                  <b-form-select
                    v-model="form.status"
                    :options="statusOptions"
                    class="w-auto h-auto"
                  />
                </b-list-group-item>
                <b-list-group-item><span class="font-weight-bold">Assigned To: </span>{{ detail.assignee.name }}</b-list-group-item>
              </b-list-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card title="Notes">
              <b-textarea v-model="form.notes" />
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="detail.mobile_tablet">
          <b-col
            v-for="(item, index) in detail.mobile_tablet"
            :key="index"
          >
            <b-card title="Devices">
              <b-row>
                <b-col>
                  <div class="d-flex justify-content-start">
                    <div>
                      <b-img
                        fluid
                        :src="item.color.image"
                      />
                    </div>
                    <div>
                      <p class="font-weight-bold">
                        {{ item.model.name }} {{ item.color.name }}
                      </p>
                      <p
                        v-for="issue in item.issues"
                        :key="issue.id"
                      >
                        {{ issue.name }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="detail.business_support">
          <b-col>
            <b-card title="Business Support">
              <b-list-group>
                <b-list-group-item>Session Time: {{ detail.business_support[0].session.time }}</b-list-group-item>
                <b-list-group-item>Session Price: &#163;{{ detail.business_support[0].session.price }}</b-list-group-item>
              </b-list-group>
              <p class="mt-2 font-weight-bold">
                Issues
              </p>
              <ul>
                <li
                  v-for="issue in detail.business_support[0].issues"
                  :key="issue.id"
                >
                  {{ issue.name }} ({{ issue.average_time }})
                </li>
              </ul>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="detail.technical_support">
          <b-col>
            <b-card title="Technical Support">
              <b-list-group>
                <b-list-group-item>Session Time: {{ detail.technical_support[0].session.time }}</b-list-group-item>
                <b-list-group-item>Session Price: &#163;{{ detail.technical_support[0].session.price }}</b-list-group-item>
              </b-list-group>
              <p class="mt-2 font-weight-bold">
                Issues
              </p>
              <ul>
                <li
                  v-for="issue in detail.technical_support[0].issues"
                  :key="issue.id"
                >
                  {{ issue.name }} ({{ issue.average_time }})
                </li>
              </ul>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-overlay>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ordersModule = createNamespacedHelpers('orders')
export default {
  data() {
    return {
      loader: false,
      detail: null,
      form: {
        notes: '',
        status: '',
      },
      statusOptions: [
        { value: 'Waiting', text: 'Waiting' },
        { value: 'In House', text: 'In House' },
        { value: 'Cancelled', text: 'Cancelled' },
        { value: 'Done', text: 'Done' },
      ],
    }
  },
  async mounted() {
    await this.getOrder()
  },
  methods: {
    ...ordersModule.mapActions(['FETCH_ORDER_BY_ID', 'UPDATE_ORDER_BY_ID']),
    async getOrder() {
      try {
        this.loader = true
        const resp = await this.FETCH_ORDER_BY_ID(this.$route.params.id)
        // eslint-disable-next-line prefer-destructuring
        this.detail = resp[0]
        this.form.notes = this.detail.notes || ''
        this.form.status = this.detail.status || ''
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },
    getStatusVariant(status) {
      switch (status) {
        case 'Waiting':
          return 'warning'
        case 'In House':
          return 'primary'
        case 'Cancelled':
          return 'danger'
        case 'Done':
          return 'success'

        default:
          break
      }
      return 'danger'
    },
    async updateOrder() {
      try {
        const resp = await this.UPDATE_ORDER_BY_ID({ id: this.$route.params.id, data: this.form })
        if (resp) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Order Updated!',
              icon: 'checkIcon',
              variant: 'success',
              text: 'Order has been updated successfully!',
            },
          })
        }
      } catch (error) {
        // console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
